import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { isEmptyObj, jsonParse } from 'src/app/helper/class/utilityHelper';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {

  constructor(private http: HttpClient) { }

  saveUser(data: any) {
    return lastValueFrom(this.http.post(`/users/saveUser`, data))
  }

  isUnique(field: any, id: any) {
    return lastValueFrom(this.http.get(`/users/isUnique/${field}/${id}`))
  }
  isEmailUnique(username: any) {
    return lastValueFrom(this.http.get(`/users/isEmailUnique/${username}`))
  }

  getUserData(id: any) {
    return lastValueFrom(this.http.get(`/users/getUserDetails/${id}`))
  }
  getAllUser(ev: any) {
    const lasyP = isEmptyObj(ev) ? '' : '/' + JSON.stringify(ev);
    return lastValueFrom(this.http.get(`/users/getUserAll${lasyP}`))
  }
  getAllInactive(ev: any) {
    const lasyP = isEmptyObj(ev) ? '' : '/' + JSON.stringify(ev);
    return lastValueFrom(this.http.get(`/users/getAllInactive${lasyP}`))
  }

  getUserById(id: any) {
    return lastValueFrom(this.http.get(`/users/getUserDetails/${id}`))
  }
  getUserByUserName(username: any) {
    return lastValueFrom(this.http.get(`/users/getUserByUserName/${username}`))
  }
  deleteUser(id: any) {
    return lastValueFrom(this.http.delete(`/users/deleteUser/${id}`))
  }
  makeActive(id: any) {
    return lastValueFrom(this.http.put(`/users/makeActive/${id}`, {}))
  }
  getDashboardData() {
    return lastValueFrom(this.http.get(`/dashboard/getData`))
  }



  saveAlliancePartner(data) {
    return lastValueFrom(this.http.post('alliance/save', data));
  }
  getListAlliancePartner(ev: any) {
    const lasyP = isEmptyObj(ev) ? '' : '/' + JSON.stringify(ev);
    return lastValueFrom(this.http.get(`alliance/getList/${lasyP}`));
  }
  getDistributionCenterById(id: any) {
    return lastValueFrom(this.http.get(`alliance/getDetails/${id}`));
  }
  deleteDistributionCenter(id: any) {
    return lastValueFrom(this.http.get(`alliance/inActive/${id}`));
  }

  getWhether() {
    const weatherAPI = 'http://api.openweathermap.org/data/2.5/weather?',
      weatherParams = '&units=metric&APPID=eb03b1f5e5afb5f4a4edb40c1ef2f534',
      getIP = 'http://ip-api.com/json/';
    // lastValueFrom(     return this.http.get(getIP))
    return this.http.get(getIP).pipe(mergeMap((location: any) => this.http.post(`/dashboard/getWhetherUrl/${location.city}`, location)))
  }
}